@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-Regular.woff2') format('woff2'), url('NBInternationalPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-Bold.woff2') format('woff2'), url('NBInternationalPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-Italic.woff2') format('woff2'), url('NBInternationalPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-BoldItalic.woff2') format('woff2'),
    url('NBInternationalPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-LightIta.woff2') format('woff2'), url('NBInternationalPro-LightIta.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NB International';
  src: url('NBInternationalPro-Light.woff2') format('woff2'), url('NBInternationalPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
