.notification-container {
	box-sizing: border-box;
	position: fixed;
	z-index: 999999;
	bottom: 12px;
	left: 50%;
	transform: translateX(-50%);
	transition: transformY .6s ease-in;
	animation: toast-in .7s;
	max-width: 100vw;
}

.notification {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	padding: 30px;
	max-height: 100px;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
}

.notification:hover {
	opacity: 1;
}

.notification-message {
	margin: 0;
	text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notification-message a, .notification-message span {
	color: #00FF00;
}

.toast {
    width: 100vw;
	color: #fff;
	padding: 10px 20px;
    box-sizing: border-box;
}

.notification-container button {
	position: relative;
	float: right;
	color: #00FF00;
	outline: none;
	border: none;
	opacity: .8;
	line-height: 1;
	font-size: 16px;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	top: 10px;
    right: -9px;
}

@keyframes toast-in {
	from {
		transform: translateY(100%) translateX(-50%);;
		
	}
	to {
		transform: translateY(0) translateX(-50%);;
	}
}

@media screen and (min-width: 768px) {
	.toast {
	    width: 768px;
		color: #fff;
		padding: 10px 40px;
	    box-sizing: border-box;
	}

	.notification-message {
		white-space: nowrap;
	}

	.notification-container button {
		top: unset;
		right: unset;
	}
}